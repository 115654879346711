<template>
  <YoTable
    ref-specify="FormType1"
    :api-endpoint="apiEndpoint"
    :title-tbl="this.$t('Dashboards') + ' Form Type 1 (T1)'"
    :sub-title-tbl="this.$t('Land Vehicles')"
    :fields="fields"
    :field-history="fieldHistory"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :disable-edit="disableEdit"
    :multiple-header="multipleHeader"
    :force-disable-crud="true"
    enable-create-form="monitoring-jalur-darat-form-type-1-wizard"
    placeholder-search="LHCode"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'form1_land_vehicle',
      disableEdit: true,
      multipleHeader: {
        startFrom: this.$can('manage', 'qrcode') ? 5 : 4,
        content: [
          {
            value: 'QRCODE',
            span: '2',
            align: 'text-center'
          },
          {
            value: 'CLOCK',
            span: '3',
            align: 'text-center'
          },
          {
            value: 'NAME',
            span: '2',
            align: 'text-center'
          },
          {
            value: 'Kendaraan',
            span: '4',
            align: 'text-center'
          },
          {
            value: 'SCHEDULE',
            span: '3',
            align: 'text-center'
          }
        ],
        noEnd: false,
        endFrom: this.$can('manage', 'qrcode') ? 8 : 7
      },
      fields: [
        {
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          key: 'group.name',

          minWidth: '130px',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'action_form',

          label: 'Aksi',
          acl:
            !!(
              this.$can('manage', 'qrcode') ||
              this.$can('manage', 'special') ||
              getUserData().group?.name === 'TEM'
            ) || this.$store.state.app.roleName === 'viewer'
        },
        {
          searchModel: 'form_status',
          search: {
            type: 'text'
          },
          // stickyColumn: true,
          key: 'form_status',

          minWidth: '150px',
          label: 'Form Status',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'no_document',
          search: {
            type: 'text'
          },
          // stickyColumn: true,
          key: 'no_document',

          minWidth: '180px',
          label: 'No Document',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          key: 'action_qrcode',

          minWidth: '100px',
          label: 'Action',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'qrcode_status',
          search: {
            type: 'text'
          },

          key: 'qrcode_status',

          minWidth: '150px',
          label: 'QR Status',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'clock_in_time',
          search: {
            type: 'checkbox',
            option: [
              {
                title: 'Ada',
                value: '1'
              }
            ]
          },
          key: 'clock_in_time',

          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'CLOCK IN',
          sortable: true,
          input: {
            type: 'time',
            format: 'DD MMMM YYYY  hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'clock_out_time',
          search: {
            type: 'checkbox',
            option: [
              {
                title: 'Ada',
                value: '1'
              }
            ]
          },
          key: 'clock_out_time',

          minWidth: '180px',
          verticalAlign: 'middle',
          label: 'CLOCK OUT',
          sortable: true,
          input: {
            type: 'time',
            format: 'DD MMMM YYYY  hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'diff_clock',
          search: {
            type: 'text'
          },
          key: 'diff_clock',

          label: 'DIFF CLCOK',
          minWidth: '260px',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },

        {
          searchModel: 'transportir.name_transportir',
          search: {
            type: 'text'
          },
          key: 'transportir.name_transportir',

          minWidth: '200px',
          label: 'Transportir',
          placeholder: 'Name Transportir',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          searchModel: 'agen.name_agen',
          search: {
            type: 'text'
          },
          key: 'agen.name_agen',

          label: 'Agen',
          minWidth: '200px',
          placeholder: 'Name',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        {
          key: 'dm_land_vehicle.plat_no_pol',

          label: 'Plat No POL',
          placeholder: 'Cth: KT 1503 DR',
          minWidth: '150px',
          sortable: true,
          stickyColumn: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_land_vehicle.plat_no_pol',
          search: {
            type: 'text'
          }
        },
        {
          key: 'dm_land_vehicle.capacity',

          label: 'Capacity LTR',
          placeholder: 'Cth: KT 1503 DR',
          minWidth: '150px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_land_vehicle.capacity',
          search: {
            type: 'text'
          }
        },
        {
          key: 'jml_muatan',

          label: 'Muatan LTR',
          minWidth: '150px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'jml_muatan',
          search: {
            type: 'text'
          }
        },
        {
          key: 'dm_personal.full_name',

          label: 'Driver',
          placeholder: 'Sopir',
          minWidth: '190px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'dm_personal.full_name',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'schedule_in',
          search: {
            type: 'date'
          },
          key: 'schedule_in',

          label: 'SCHEDULE IN',
          sortable: true,
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'schedule_out',
          search: {
            type: 'date'
          },
          key: 'schedule_out',

          label: 'SCHEDULE OUT',

          sortable: true,
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'diff_schedule',
          search: {
            type: 'text'
          },
          key: 'diff_schedule',

          label: 'DIFF SCHEDULE',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'user.full_name',

          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text'
          }
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number'
          },
          key: 'user.phone',

          minWidth: '170px',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true }
        },
        {
          key: 'created_at',

          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'created_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'updated_at',

          label: 'Updated AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'updated_at',
          search: {
            type: 'date'
          }
        },
        {
          key: 'approval_by',
          label: 'Approval BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'approval_by',
          search: {
            type: 'text'
          }
        },
        {
          key: 'form_expired_at',

          label: 'Form Expired AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          },
          searchModel: 'form_expired_at',
          search: {
            type: 'date'
          }
        },
        {
          searchModel: 'emergency_action',
          search: {
            type: 'text'
          },
          key: 'emergency_action',

          label: 'Emergency IN OUT',
          sortable: true,
          input: { type: 'input-noarea' },
          acl: this.$can('manage', 'qrcode')
        },
        {
          key: 'id',
          label: 'ID',
          minWidth: '150px',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'id',
          search: {
            type: 'text'
          }
        }
      ],
      fieldHistory: [
        {
          label: 'No Document',
          field: 'no_document',
          filterOptions: {
            enabled: true,
            placeholder: 'Search No Document'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        }
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        // name: '',
        // route: '',
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.form1_land_vehicles
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
          filtered: 'Pending Approve'
        }
      }
    }
  }
}
</script>
